/**
 * @flow
 */

/** This error exists for usage when throwing errors that are not meant to be presented to users, and thus
 * don't need to be translated. Our translation linting ignores the error message for this class. You can also imagine
 * a situation where we _do_ end up handling these errors (e.g. a React error boundary). In those cases, we can check
 * if our errors are 'InternalOnlyError', and show generic user-facing message that is marked for translation.
 */
export class InternalOnlyError extends Error {
  constructor(message: string) {
    super(message);
    // eslint-disable-next-line i18next/no-literal-string
    this.name = "InternalOnlyError";
  }
}
