/**
 * @flow
 */

import $ from "jquery";
import includes from "lodash/includes";
import { InternalOnlyError } from "../../shared/utils/errors";

type RowType = { id: number, unicode: string } | { id: number, display_str: string } | string;
type DisplayFn = (count: number) => string;
const occupationClassOptionDisplayFn = (count: number) =>
  window.ngettext("--- %(count)s Occupation Class ---", "--- %(count)s Occupation Classes ---", count);
const occupationOptionDisplayFn = (count: number) =>
  window.ngettext("--- %(count)s Occupation ---", "--- %(count)s Occupations ---", count);
const departmentOptionDisplayFn = (count: number) =>
  window.ngettext("--- %(count)s #[JARGON:Department] ---", "--- %(count)s #[JARGON:Departments] ---", count);
const facilityOptionDisplayFn = (count: number) =>
  window.ngettext("--- %(count)s Facility ---", "--- %(count)s Facilities ---", count);

const DependencyToSelectDisplay = {
  department: window.pgettext(
    "This appears in a dropdown selector when the user is required to select a department in order to make another " +
      "selection that depends on the chosen department.",
    "--- Select A #[JARGON:Department] ---"
  ),
  union: window.pgettext(
    "This appears in a dropdown selector when the user is required to select a union in order to make another " +
      "selection that depends on the chosen union.",
    "--- Select A Union ---"
  ),
  employee: window.pgettext(
    "This appears in a dropdown selector when the user is required to select an employee in order to make " +
      "another selection that depends on the chosen employee.",
    "--- Select An Employee ---"
  ),
  facility: window.pgettext(
    "This appears in a dropdown selector when the user is required to select a facility in order to make another " +
      "selection that depends on the chosen facility.",
    "--- Select A Facility ---"
  ),
  facility_group: window.pgettext(
    "This appears in a dropdown selector when the user is required to select a facility group in order to make " +
      "another selection that depends on the chosen facility group.",
    "--- Select A Facility Group ---"
  ),
  vacation_process: window.pgettext(
    "This appears in a dropdown selector when the user is required to select a vacation process in order to " +
      "make another selection that depends on the chosen vacation process.",
    "--- Select A Vacation Process ---"
  ),
};
type DependencysKeys = $Keys<typeof DependencyToSelectDisplay>;

type UrlDictEntry = {|
  // Each field shows default text above all the valid options, as the first option in the select. This option, if
  // chosen, is treated as 'no selection' and the field isn't used as a filter. For example, facility would show:
  // "--- 4 Facilities ---" as the first option, followed by the 4 facility options. This function should return that
  // text using a translation function like ngettext, as it could be "--- 1 Facility ---".
  optionDisplayFn: (number) => string,
  // Each dynamic model choice field has a list of dependent elements that it listens to if this is not the case, then
  // use a simpler field. This is just a comma separated list of the names of the fields that will be listened to
  // Note: this field theoretically accepts an arbitrary list of dependencies, but has only been tested with 2.
  dependencies: Array<DependencysKeys>,
  // Rows attribute is an optional attribute to help parse the API response. If the API response is in the format:
  //  { data: {__rowsAttribute__: [...]} }
  // Where '__rowsAttribute__' is a variable key like 'scheduled_shifts', you can supply the 'rowsAttribute' value
  // 'scheduled_shifts' to tell the parsing logic to check `data.scheduled_shifts` for the data rows. This is typically
  // used when parsing responses to dynamic rest APIViewSets.
  rowsAttribute?: string,
  // Each listening field or combination of listening fields has its own query url that will return a list of items
  // to update the model choice field list with. The key is the dependency name, and the value is the url.
  // The code subs in the integer value of the selected item in the listener field for the wildcard "(\d+)" (currently
  // only integers are supported). The code assumes that the wildcard follows the name of the listener field directly
  // e.g. department/(\d+)/occupations and replaces it with the name followed by the value: department/23/occupations
  //    "department": "/ajax/modelchoice/department/(\d+)/occupations",
  // When there are multiple dependencies for a query url, there are a combinatorial number of possible keys, so the
  // code assumes that the key is a space separated string in alphabetical order, and will not find the dictionary
  // entry if this is not the case. e.g.
  //    "department union": "ajax/modelchoice/department/(\d+)/union/(\d+)/occupations"
  [string]: string,
|};
type UrlDict = {|
  // Each type of model choice field gets its own key, this key is the class that got added to the field when
  // subclassing ListeningCoerceChoiceField the code looks through all the classes on the elements and picks the one
  // that contains the word "depending" so make sure that you use that word when creating your class. e.g.
  // 'occupation_depending_on_department_and_union'.
  [entry: string]: UrlDictEntry,
|};

/* This is the dictionary that holds all the query information for the dynamic model choice fields. */
const urlDicts: UrlDict = {
  occupation_depending_on_department_and_union: {
    optionDisplayFn: occupationOptionDisplayFn,
    dependencies: ["department", "union"],
    department: "/ajax/modelchoice/department/(d+)/occupations/",
    "department union": "/ajax/modelchoice/department/(d+)/union/(d+)/occupations/",
  },
  occupation_depending_on_employee: {
    optionDisplayFn: occupationOptionDisplayFn,
    dependencies: ["employee"],
    employee: "/api/v1/occupations/for-employee/(?P<employee>d+)",
  },
  facility_depending_on_facility_group: {
    optionDisplayFn: facilityOptionDisplayFn,
    dependencies: ["facility_group"],
    facility_group: "/ajax/modelchoice/facility_group/(d+)/facilities/",
  },
  department_depending_on_facility: {
    optionDisplayFn: departmentOptionDisplayFn,
    dependencies: ["facility"],
    facility: "/ajax/modelchoice/facility/(d+)/departments/",
  },
  // Use to get departments a user can SCHEDULE.
  department_user_can_schedule_depending_on_facility: {
    optionDisplayFn: departmentOptionDisplayFn,
    dependencies: ["facility"],
    facility: "/ajax/modelchoice/facility/(d+)/departments_user_can_schedule/",
  },
  // Use to get departments a user can MANAGE.
  department_depending_on_user_permissions: {
    optionDisplayFn: departmentOptionDisplayFn,
    dependencies: ["facility"],
    facility: "/ajax/modelchoice/facility/(d+)/user_permitted_departments/",
  },
  department_depending_on_employee: {
    optionDisplayFn: departmentOptionDisplayFn,
    dependencies: ["employee"],
    employee: "/api/v1/departments/for-employee/(?P<employee>d+)",
  },
  occupation_class_depending_on_facility: {
    optionDisplayFn: occupationClassOptionDisplayFn,
    dependencies: ["department"],
    department: "/ajax/modelchoice/department/(d+)/occupationclasses/",
  },
  descriptive_occupation_class_depending_on_facility: {
    optionDisplayFn: occupationClassOptionDisplayFn,
    dependencies: ["department"],
    department:
      "/api/v1/occupation_classes/?filter{occupations.position_assignments.department}=(?P<department>d+)&exclude[]=occupations",
    rowsAttribute: "occupation_classes",
  },
  occupation_depending_on_department: {
    optionDisplayFn: occupationOptionDisplayFn,
    dependencies: ["department"],
    department: "/ajax/modelchoice/department/(d+)/occupations/",
  },
  occupation_depending_on_home_assignment_department: {
    optionDisplayFn: occupationOptionDisplayFn,
    dependencies: ["department"],
    department: "/api/v1/occupations/?filter{home_assignments.department}=(?P<department>d+)",
    rowsAttribute: "occupations",
  },
  scheduled_shift_depending_on_employee: {
    optionDisplayFn: (count) => window.ngettext("--- %(count)s Shift ---", "--- %(count)s Shifts ---", count),
    dependencies: ["employee"],
    employee: "/api/v1/employee/(?P<employee>d+)/scheduled-shifts/today-and-upcoming",
    rowsAttribute: "scheduled_shifts",
  },
  annual_vacation_vacation_round_name_depending_on_vacation_process: {
    optionDisplayFn: (count) =>
      window.ngettext("--- %(count)s Vacation Round ---", "--- %(count)s Vacation Rounds ---", count),
    dependencies: ["vacation_process"],
    vacation_process: "/api/v1/annual-vacation/vacation-processes/(?P<vacation_process>d+)/vacation-round-names/",
  },
  annual_vacation_vacation_group_depending_on_vacation_process: {
    optionDisplayFn: (count) =>
      window.ngettext("--- %(count)s Vacation Group ---", "--- %(count)s Vacation Groups ---", count),
    dependencies: ["vacation_process"],
    vacation_process:
      "/api/v1/annual-vacation/vacation-processes/(?P<vacation_process>d+)/vacation-group-ids-and-display-strs/",
  },
  home_occupation_depending_on_department_and_union: {
    optionDisplayFn: (count) =>
      window.ngettext("--- %(count)s Home Occupation ---", "--- %(count)s Home Occupations ---", count),
    dependencies: ["department", "union"],
    department: "/api/v1/occupations/?filter{employees.department}=(?P<department>d+)",
    "department union":
      "/api/v1/occupations/?filter{employees.department}=(?P<department>d+)&filter{employees.union}=(?P<union>d+)",
    rowsAttribute: "occupations",
  },
};

const createKeyFromList = (list: Array<DependencysKeys>) => {
  list.sort();
  return list.join(" ");
};

const determineCoerceFieldValue = (selector, rows: Array<RowType>) => {
  // If a default value was passed in to the form via the url params, then we want to pre-populate the field with the
  // passed in value.
  const urlParams = window.location.search.replace("?", "").split("&");
  // eslint-disable-next-line i18next/no-literal-string
  const idPrefix = "id_";
  let selectorName = selector.id;
  if (selectorName.slice(0, idPrefix.length) === idPrefix) {
    selectorName = `${selectorName.slice(idPrefix.length)}=`;
  }
  let value = "";
  for (const param of Array.from(urlParams)) {
    if (param.slice(0, selectorName.length) === selectorName) {
      value = param.slice(selectorName.length);
    }
  }
  for (const row: RowType of rows) {
    // RowType can be a plain string, which doesn't have an "id", so check for that.
    if (typeof row !== "string" && row.id.toString() === value) {
      return row.id;
    }
  }
  return "";
};

const fillSelectEmpty = (selector: Element, unfilledDependencyMessage) => {
  const newElem = $("<select/>");
  // eslint-disable-next-line i18next/no-literal-string
  newElem.append(`<option value="">${unfilledDependencyMessage}</option>`);
  // $FlowFixMe - our jQuery flow types don't think 'html' accepts an Array of Element, but it seems to.
  $(selector).html(newElem.children());
  return $(selector).change();
};

const fillSelect = (selector: Element, rows: Array<RowType>, displayNameFn: DisplayFn) => {
  const newElem = $("<select/>");
  // The top select option is always something like "--- 1 Department ---" or "--- 3 Departments ---".
  newElem.append(
    // eslint-disable-next-line i18next/no-literal-string
    `<option value="">${window.namedInterpolate(displayNameFn(rows.length), { count: rows.length })}</option>`
  );
  rows.forEach((row: RowType) => {
    let rowContent: string;
    let rowValue;
    if (typeof row === "string") {
      rowValue = row;
      rowContent = row;
    } else {
      rowValue = row.id;
      if (typeof row.display_str === "string") {
        rowContent = row.display_str;
      } else if (typeof row.unicode === "string") {
        rowContent = row.unicode;
      } else {
        throw new InternalOnlyError("Invalid row configuration.");
      }
    }
    // eslint-disable-next-line i18next/no-literal-string
    return newElem.append(`<option value="${rowValue}">${rowContent}</option>`);
  });

  // $FlowFixMe - our jQuery flow types don't think 'html' accepts an Array of Element, but it seems to.
  $(selector).html(newElem.children());
  $(selector).val(determineCoerceFieldValue(selector, rows));
  return $(selector).change();
};

document.addEventListener("DOMContentLoaded", () => {
  // Dynamically filled model choice field. Using this requires that a custom model field
  // be defined that subclasses eLib.forms.ListeningCoerceChoiceField and that query information
  // be added to the url_dicts dictionary below.
  // Used by almost every form we have where one drop-down depends on another drop-down.
  $("select.listening-model-choice-field").each((idx: number, selectFieldElement: Element) => {
    const listenerClassList = selectFieldElement.className.split(" ");
    let listenerClass = "";
    for (const className of listenerClassList) {
      if (includes(className, "depending")) {
        listenerClass = className;
      }
    }
    const listenerDict = urlDicts[listenerClass];
    const { dependencies } = listenerDict;

    const updateDependencies = (
      filledDependencies: Array<DependencysKeys>,
      dependencyValues: { [key: DependencysKeys]: string }
    ) => {
      // this function updates the parent field when a dependency changes

      // we need to know what dependencies haven't been filled yet, so we can inform the user.
      const unfilledDependencies = dependencies.filter((dependency) => !filledDependencies.includes(dependency));

      // look for the query that uses all the filled dependencies
      const key = createKeyFromList(filledDependencies);

      if (listenerDict[key]) {
        // query information exists for these dependencies, so go ahead and make an ajax call
        let query = listenerDict[key];
        for (const name of filledDependencies) {
          // replace all the wildcard with the query values
          // eslint-disable-next-line no-useless-escape
          query = query.replace(`${name}/(\d+)`, `${name}/${dependencyValues[name]}`);
          query = query.replace(`(?P<${name}>d+)`, dependencyValues[name]);
        }

        // FIXME: HSMS-3893: Currently we can't use fetchWithAuth, our standard ajax interface, because some of the
        //  listening model choice field uses very old endpoints that don't wrap the success response data in a 'data'
        //  attribute. They return data in a 'row' attribute. These problematic methods are in regiondb/common/views.py
        //  and use JsonQuerySetResponse, which uses the old format. We should update those endpoints.
        // FIXME: This is our only remaining usage of jQuery's AJAX method(s).
        return $.ajax({
          url: query,
          method: "GET",
          dataType: "json",
          data: {},
          success: (data) => {
            // These are the object shapes we encounter from the ajax response
            // 1. {rows: [{"id": 1, "unicode": "one"}, {"id": 2, "unicode": "two"}]}
            // 2. {data: {__rowsAttribute__: [{"id": 1, "display_str": "one"}, {"id": 2, "display_str": "two"}]}}
            //     - '__rowsAttribute__' is variable. e.g. "occupations"
            // 3. {data:  [{"id": 1, "display_str": "one"}, {"id": 2, "display_str": "two"}]}
            // 4. {data:  ["one", "two"]}
            let rows: Array<RowType> = [];
            if ("rows" in data) {
              ({ rows } = data);
            } else if (data.data) {
              if (listenerDict.rowsAttribute) {
                rows = data.data[listenerDict.rowsAttribute];
              } else {
                rows = data.data;
              }
            }
            return fillSelect(selectFieldElement, rows, listenerDict.optionDisplayFn);
          },
        });
      }
      // No query information exists, so 'inform' the developer which dependency needs to be filled.
      if (key) {
        throw new InternalOnlyError(
          `No query url exists for dependencies: ${key}. Make sure dictionary keys are in alphabetic order`
        );
      }
      return fillSelectEmpty(selectFieldElement, DependencyToSelectDisplay[unfilledDependencies[0]]);
    };

    const loadFieldOptions = () => {
      // this function updates the parent field when a dependency changes
      // please note that the event may be null as this method is run manually when first loaded
      const filledDependencies = [];
      const dependencyValues = {};
      for (const name of dependencies) {
        // Find the field for each dependency. Currently, we only support input or select fields. When we have the input
        // or select field with the dependency's name, we have to filter out any of them that are hidden because
        // in certain search forms (such as the minimized search field for searching shifts) there will be both
        // select and input elements with the same name but one is hidden. Other than that, there should never be
        // both input and select elements with the same name.
        let dependentElements = [];
        dependentElements.push(...Array.from($(`input[name=${name}]`) || []));
        dependentElements.push(...Array.from($(`select[name=${name}]`) || []));
        dependentElements = dependentElements
          .filter(Boolean)
          .filter((element) => element.type !== "hidden" || element.dataset.supportsHiddenDynamicFields != null);

        if (dependentElements.length > 1) {
          throw new InternalOnlyError("I can't handle multiple fields with the same name!");
        }
        const dependentValue = dependentElements?.[0]?.value;
        // if the dependency is filled we will use it for the query, but that's ok if it isn't
        if (dependentValue) {
          filledDependencies.push(name);
          dependencyValues[name] = dependentValue;
        }
      }

      return updateDependencies(filledDependencies, dependencyValues);
    };

    // attach to all the listeners
    const result = [];
    for (const event of dependencies) {
      const selectElement = $(`select[name=${event}]`);
      selectElement.change(loadFieldOptions);
      selectElement.each(loadFieldOptions);

      // Attaches to auto suggest input dependencies
      const autoSuggestInput = $(`input[name=${event}]`);
      if (autoSuggestInput.length > 0) {
        // $FlowFixMe - flow isn't on the up-and-up with custom events just yet.
        autoSuggestInput[0].addEventListener(
          "signal-auto-suggest-input-changed",
          (e: CustomEvent) => {
            if (e.detail) {
              return updateDependencies([event], { [(event: string)]: e.detail });
            }
            return undefined;
          },
          false
        );
        result.push(autoSuggestInput.each(loadFieldOptions));
      } else {
        result.push(undefined);
      }
    }
    return result;
  });
});
